import { template as template_8254a3b6f3d949029f73b376f9fd1de9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8254a3b6f3d949029f73b376f9fd1de9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
