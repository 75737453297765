import { template as template_9d84fa2b31304025bd8256765efe9c0a } from "@ember/template-compiler";
const FKLabel = template_9d84fa2b31304025bd8256765efe9c0a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
